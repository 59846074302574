export const FirebaseConfig = {
	"projectId": "gofleeta",
	"appId": "1:167025944598:web:b633da92a1570a86ca6d37",
	"databaseURL": "https://gofleeta-default-rtdb.firebaseio.com",
	"storageBucket": "gofleeta.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAUSXQnC4bDNHNEghMfyteTojnJscI-piM",
	"authDomain": "gofleeta.firebaseapp.com",
	"messagingSenderId": "167025944598",
	"measurementId": "G-53YK1E2SZL"
};